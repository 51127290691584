/*!
 * Template Name: Decibel - Bootstrap 4 template for bands and musicians
 * Version: 4.1
 * Author: Xiaoying Riley
 * Copyright: 3rd Wave Media Ltd.
 * Website: http://themes.3rdwavemedia.com/
 * Twitter: @3rdwave_themes
*/

/*************************** Theme Colours ****************************/
// Define theme colour scheme
$theme-color: #5bb66f;
$text-color-primary: #101113;
$text-color-secondary: #4f545e;
$text-color-grey: #788191;
$black: #000;
$smoky-white: #f5f5f5;
$grey: #ccc;
$divider: #eee;
$error: #E65348;

$theme-1: #5bb66f; /* Green (default) */
$theme-2: #2696C2; /* Blue (light) */
$theme-3: #C9091A; /* Red */
$theme-4: #F2C020; /* Yellow */
$theme-5: #0F5EE8; /* Blue (dark) */
$theme-6: #1DAEB0; /* Aqua */
$theme-7: #6B64AE; /* Purple */
$theme-8: #EC3A81; /* Pink */
$theme-9: #C79F7C; /* Gold */
$theme-10: #AEBB4B; /* Lime */


/*************************** Theme Logos ****************************/
.logo-holder {
    background-image: url('../assets/images/logo-1.svg');
}

/*************************** Theme Styles ****************************/
// Import theme styles
@import "theme/mixins";
@import "theme/base";

